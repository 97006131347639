
import { Component, Vue } from 'vue-property-decorator';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import { Venue } from '@/interfaces/models/Venue';
import VenueTable from '@/components/venue/VenueTable.vue';
import VConfirm from '@/components/shared/VConfirm.vue';
import { Permission } from '@/enums/Permission';
import Filter from '@/interfaces/api/Filter';
import { AxiosResponse } from 'axios';
import { toUrl } from '@/util/helper';
import moment from 'moment';
import DataApiService from '@/api/http/DataApiService';
import VenueApiService from '../../api/http/VenueApiService';

const venue = namespace('venue');

@Component({
  components: { VConfirm, VenueTable, VWrapper },
})
export default class Venues extends Vue {
  @venue.State('active') public venue!: Venue;

  @venue.Action('activateService') public activateService!: any;
  @venue.Action('deactivateService') public deactivateService!: any;
  @venue.Action('piggyLoyaltytoggle') public piggyLoyaltyActivated!: any;
  @venue.Action('fetch') public getVenues!: any;
  @venue.Action('destroy') public deleteVenue!: any;
  @venue.Action('softDelete') public archive!: any;
  @venue.Action('setFilter') public setVenueFilter!: any;
  @venue.State('items') public venues!: Venue[];
  @venue.State((state) => state.pagination.total) public total!: number;
  @venue.State('filter') public venueFilter!: Filter;
  @venue.Action('update') public update!: any;

  public $refs!: {
    confirmGenerateWebAppSlug: InstanceType<typeof VConfirm> & {
      setText: (str: string) => void;
      open: (result: {} | PromiseLike<{}> | undefined) => boolean;
    };
  };

  public editMasterIdDialog: { venue: string | null; isVisible: boolean; masterId: string } = {
    venue: null,
    isVisible: false,
    masterId: '',
  };

  public mounted() {
    this.$refs.confirmGenerateWebAppSlug.setText(`${this.$t('venue.notification.webAppSlug')}`);
  }

  public async created() {
    this.$startLoading('venue');
    this.setVenueFilter({});
    await this.getVenues({ page: 1 });
    this.$stopLoading('venue');
  }

  public create() {
    if (this.$can(Permission.VENUE_CREATE)) {
      this.$router.push({ name: 'venue.create' });
    } else {
      this.$router.push({ name: 'venue.createSimple' });
    }
  }

  public async destroy(venue: Venue) {
    this.$startLoading('venue');
    await this.deleteVenue({ id: venue._id });
    this.$stopLoading('venue');
  }

  public exportCsv() {
    this.$startLoading('venueCsv');
    const api: DataApiService = new DataApiService();
    api
      .getVenueCsv(this.venueFilter, this.$i18n.locale)
      .then((res: AxiosResponse) => toUrl(res.data, `venues-${moment().format('DD-MM-YYYY')}.csv`))
      .finally(() => {
        this.$stopLoading('venueCsv');
      });
  }

  public async filter(filter: Filter) {
    this.$startLoading('venue');
    this.setVenueFilter(filter);
    await this.getVenues({ page: 1 });
    this.$stopLoading('venue');
  }

  public async toggleServiceActivated({ item, value }: { item: Venue; value: boolean }) {
    this.$startLoading('venue');

    if (value) {
      this.deactivateService({ venue: item._id, isServiceActivated: value });
    } else {
      this.activateService({ venue: item._id, isServiceActivated: value });
    }
    this.$stopLoading('venue');
  }

  public async togglePiggyLoyalty({ item, value }: { item: Venue; value: boolean }) {
    this.$startLoading('venue');
    if (item) {
      this.piggyLoyaltyActivated({ venue: item._id, piggyLoyaltyEnabled: !value });
    }
    this.$stopLoading('venue');
  }

  public confirmGenerationWebAppSlug() {
    this.$refs.confirmGenerateWebAppSlug.open(async (result: {} | PromiseLike<{}> | undefined) => {
      if (await result) {
        this.generateWebAppSlug();
      }
    });
  }

  public generateWebAppSlug() {
    this.$startLoading('webAppSlug');
    const api: VenueApiService = new VenueApiService();
    api
      .generateWebAppSlug()
      .then((res: AxiosResponse) => console.log(res))
      .catch((e) => console.log(e))
      .finally(() => {
        this.$stopLoading('webAppSlug');
      });
  }

  public openEditMasterIdDialog(venue: Venue) {
    this.editMasterIdDialog.venue = venue._id;
    this.editMasterIdDialog.masterId = venue.masterId as string;
    this.editMasterIdDialog.isVisible = true;
  }

  public closeEditMasterIdDialog() {
    this.editMasterIdDialog.isVisible = false;
    this.editMasterIdDialog.venue = '';
    this.editMasterIdDialog.masterId = '';
  }

  public updateMasterId() {
    if (!this.editMasterIdDialog.isVisible || !this.editMasterIdDialog.venue) return;
    this.update({ id: this.editMasterIdDialog.venue, masterId: this.editMasterIdDialog.masterId }).then(() => {
      this.closeEditMasterIdDialog();
    });
  }
}
